import { InfoCardBanner } from '@/components/InfoCard/InfoCardBanner';
import { BackgroundValue } from '@/theme/backgrounds';
import { FC } from 'react';

interface StrapiTextSubText {
  id: number;
  text: string;
  shadow: boolean;
  hidden: boolean;
  color: {
    id: number;
    color: string;
  };
  size: {
    id: number;
    size: string;
  };
}

export interface InfoBanner {
  title?: StrapiTextSubText;
  subtext?: StrapiTextSubText;
  description: string;
  background: {
    id: number;
    image?: string;
    url: string;
    style: BackgroundValue;
    display: boolean;
  };
  textPosition: string;
  id: number;
  shadow: any;
  hidden: any;
  rounded?: boolean;
  padding: {
    id?: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  minHeight: {
    value: string;
  };
  sectionPadding: boolean;
}

export const InfoBanner: FC<{ content: InfoBanner }> = (content) => {
  const data = content.content;

  return (
    <>
      <section
        className={`section-fade-2 relative flex flex-col ${data.sectionPadding ? 'gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24' : ''}`}
      >
        <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          <InfoCardBanner props={data}></InfoCardBanner>
        </div>
      </section>
    </>
  );
};
