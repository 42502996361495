export const backgrounds = {
  // Solid colors
  white: '#FFFFFF',
  lightBlue: '#F8FEFF',
  purple: '#352A61',

  // Blue gradient layers
  blueGradientDiagonal1: `linear-gradient(154.97deg, #9CCFD8 0%, rgba(255, 255, 255, 0) 75.69%)`,
  blueGradientDiagonal2: `linear-gradient(215.37deg, #7F7FD5 -3.52%, rgba(255, 255, 255, 0) 62%)`,
  blueGradientReverseDiagonal1: `linear-gradient(134.52deg, rgba(255, 255, 255, 0) 50.42%, rgba(127, 127, 213, 0.960784) 100%)`,
  blueGradientReverseDiagonal2: `linear-gradient(225.48deg, rgba(255, 255, 255, 0) 50.42%, #9CCFD8 100%)`,

  // Purple gradient layers
  purpleGradientDiagonal1: `linear-gradient(154.97deg, #352A61 0%, rgba(255, 255, 255, 0) 75.69%)`,
  purpleGradientDiagonal2: `linear-gradient(215.37deg, #9979B9 -3.52%, rgba(255, 255, 255, 0) 62%)`,
  purpleGradientReverseDiagonal1: `linear-gradient(134.52deg, rgba(255, 255, 255, 0) 50.42%, rgba(153, 121, 185, 0.960784) 100%)`,
  purpleGradientReverseDiagonal2: `linear-gradient(225.48deg, rgba(255, 255, 255, 0) 50.42%, #E0D7EA 100%)`,

  // Orange gradient layers
  orangeGradientDiagonal1: `linear-gradient(154.97deg, #FFF9EA 0%, rgba(255, 255, 255, 0) 75.69%)`,
  orangeGradientDiagonal2: `linear-gradient(215.37deg, #FBB954 -3.52%, rgba(255, 255, 255, 0) 62%)`,
  orangeGradientReverseDiagonal1: `linear-gradient(134.52deg, rgba(255, 255, 255, 0) 50.42%, rgba(251, 185, 84, 0.960784) 100%)`,
  orangeGradientReverseDiagonal2: `linear-gradient(225.48deg, rgba(255, 255, 255, 0) 50.42%, #FE6F01 100%)`,

  // Combined gradients
  blueGradientCombined: `linear-gradient(154.97deg, #9CCFD8 0%, rgba(255, 255, 255, 0) 75.69%), linear-gradient(215.37deg, #7F7FD5 -3.52%, rgba(255, 255, 255, 0) 62%)`,
  blueGradientReverseCombined: `linear-gradient(134.52deg, rgba(255, 255, 255, 0) 50.42%, rgba(127, 127, 213, 0.960784) 100%), linear-gradient(225.48deg, rgba(255, 255, 255, 0) 50.42%, #9CCFD8 100%)`,
  purpleGradientCombined: `linear-gradient(154.97deg, #352A61 0%, rgba(255, 255, 255, 0) 75.69%), linear-gradient(215.37deg, #9979B9 -3.52%, rgba(255, 255, 255, 0) 62%)`,
  purpleGradientReverseCombined: `linear-gradient(134.52deg, rgba(255, 255, 255, 0) 50.42%, rgba(153, 121, 185, 0.960784) 100%), linear-gradient(225.48deg, rgba(255, 255, 255, 0) 50.42%, #E0D7EA 100%)`,
  orangeGradientCombined: `linear-gradient(154.97deg, #FFF9EA 0%, rgba(255, 255, 255, 0) 75.69%), linear-gradient(215.37deg, #FBB954 -3.52%, rgba(255, 255, 255, 0) 62%)`,
  orangeGradientReverseCombined: `linear-gradient(134.52deg, rgba(255, 255, 255, 0) 50.42%, rgba(251, 185, 84, 0.960784) 100%), linear-gradient(225.48deg, rgba(255, 255, 255, 0) 50.42%, #FE6F01 100%)`,
} as const;

export type BackgroundValue = keyof typeof backgrounds;

export const getBackground = (value: BackgroundValue): string => {
  return backgrounds[value];
};
