'use client';
import { FC, useLayoutEffect } from 'react';
import Heading from '../../common/Heading';
import Text from '../../common/Text';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { RenderHTML } from '@/components/renderHtml';

interface DatProps {
  id: string;
  data: DataProps[];
}
interface DataProps {
  id: string;
  title: string;
  description: string;
  images: { data: any[] };
}

export const LandingFeatureShowcase: FC<{ content: DatProps }> = ({
  content,
  ...props
}) => {
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const handleResize = () => {
      const panels = gsap.utils.toArray('.panel-parallax');
      const texts: any[] = gsap.utils.toArray('#text-parallax');

      gsap.set(panels, {
        yPercent: (i) => (i ? 100 : 0),
      });
      gsap.set(texts[0], {
        display: 'block',
      });

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.panels-parallax',
          start: '10% 20%',
          end: () => '+=' + 100 * panels.length + '%',
          pin: '#card-parallax',
          scrub: 1,
          endTrigger: '.panels-parallax',
          pinSpacing: true,
          // markers: true,
        },
      });

      panels.forEach((panel: any, index) => {
        if (index) {
          tl.to(
            panel,
            {
              yPercent: 0,
              ease: 'none',
            },
            '+=0.25'
          );
        }
        if (index === 0) {
          tl.fromTo(
            texts[0],
            {
              opacity: 1,
              display: 'block',
            },
            {
              opacity: 0,
              duration: 1,
              ease: 'power1.inOut',
              display: 'none',
            }
          );
        } else {
          tl.fromTo(
            texts[index],
            {
              opacity: 0,
              display: 'block',
            },
            {
              opacity: 1,
              duration: 2,
              ease: 'power1.out',
              display: 'block',
            }
          );
          if (index !== texts.length - 1) {
            tl.to(texts[index], {
              opacity: 0,
              duration: 0.5,
              ease: 'power1.inOut',
              display: 'none',
            });
          }
        }
      });
    };
    handleResize();
    window.addEventListener('scroll', () => ScrollTrigger.refresh());
    return () => document.removeEventListener('scroll', handleResize);
  }, []);

  return (
    <>
      <section className='LandingFeatureShowcase relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
        <div
          className='card-parallax mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'
          id='card-parallax'
        >
          <div className='sections-par'>
            <div className='panels-parallax'>
              {content?.data &&
                content.data.map((item: DataProps, idx: number) => (
                  <>
                    <div className='panel-parallax'>
                      <div className='mx-auto mb-9 max-h-[279px] max-w-[802px] overflow-hidden '>
                        <img
                          src={
                            item?.images?.data?.[0]?.attributes?.formats?.small
                              ?.url
                          }
                          alt={item?.images?.data?.[0]?.attributes?.name}
                          title={item?.images?.data?.[0]?.attributes?.name}
                          className='h-full w-full rounded-xl object-cover'
                        />
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
          <div className='mt-3'>
            {content?.data &&
              content.data.map((item: DataProps, idx: number) => (
                <>
                  <div
                    id='text-parallax'
                    className='mb-9 flex flex-col gap-y-9'
                    style={{ display: 'none' }}
                  >
                    <Heading size={3} className='text-center'>
                      {item.title}
                    </Heading>
                    <Text size='paragraph' className='text-center lg:px-56'>
                      <RenderHTML data={item.description} />
                    </Text>
                  </div>
                </>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};
