'use client';
import { FC, useState, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import Heading from '../../common/Heading';
import Text from '../../common/Text';
import { MrImageVid } from '@/components/imageVidCard';
import { RenderHTML } from '@/components/renderHtml';

const LandingTabButton: FC<{
  label: string;
  isActive?: boolean;
  className?: string;
  children?: ReactNode;
  [key: string]: any;
}> = ({ label, isActive = false, className, children, ...props }) => {
  return (
    <button
      type='button'
      className={twMerge(
        `
            w-full min-w-52 border-b-2 py-5 text-lg font-bold leading-tight transition-all lg:max-w-52 
            ${isActive ? 'border-b-[#282A2B]/100 text-[#282A2B]' : 'border-b-[#282A2B]/0 text-[#A6A8AB] hover:text-[#282A2B]'}
          `,
        className
      )}
      title={label}
      {...props}
    >
      {label}
    </button>
  );
};

interface ContentData {
  description: string;
  title: string;
  data: {
    id: string;
    content: string;
    title: string;
  }[];
  images: {
    data: {
      id: string;
      attributes: {
        name: string;
        format: any;
        url: string;
        mime: string;
      };
    }[];
  };
}
const LandingTabInfo: FC<{
  content: ContentData;
  [key: string]: any;
}> = ({ content, ...props }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  return (
    <>
      <section className='LandingTabInfo relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
        <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          <div className='mx-auto flex w-full max-w-3xl flex-col gap-y-9 text-center '>
            <Heading size={2}>{content.title}</Heading>
            <Text size='paragraph'>
              <RenderHTML data={content.description} />
            </Text>
          </div>
        </div>
        <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem] '>
          <div className='w-full'>
            {content.images?.data?.[activeTab] &&
            content.images?.data?.[activeTab]?.attributes?.url &&
            content.images?.data?.[activeTab]?.attributes?.mime ===
              'video/mp4' ? (
              <MrImageVid
                varianbutton='white'
                type={'video'}
                size='lg'
                url={content.images?.data?.[activeTab]?.attributes?.url}
                id={`landingTabs-${activeTab}`}
              />
            ) : (
              <img
                src={content.images?.data?.[activeTab]?.attributes?.url}
                alt={content.images?.data?.[activeTab]?.attributes?.name}
                title={content?.images?.data?.[activeTab]?.attributes?.name}
                className='h-full w-full rounded-xl object-cover'
              />
            )}
          </div>
          <div
            className='mt-9 flex flex-col items-center justify-start gap-9'
            {...props}
          >
            <div className='flex flex-col gap-0 border-[#A6A8AB] text-center md:flex-row md:gap-9 md:border-b lg:gap-16'>
              {content.data
                ?.map((data: any) => data.title)
                .map((item: string, index: number) => {
                  return (
                    <LandingTabButton
                      key={index}
                      label={item}
                      isActive={index === activeTab}
                      onClick={() => setActiveTab(index)}
                    />
                  );
                })}
            </div>
            <div className='mx-auto flex w-full flex-col items-stretch justify-center text-center md:w-8/12'>
              {content.data
                ?.map((data: any) => data.content)
                .map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`
                                                text-xl transition-all
                                                ${index === activeTab ? 'block' : 'hidden'}
                                            `}
                    >
                      <RenderHTML data={item} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { LandingTabInfo };
