'use client';
import { FC, Fragment, useState } from 'react';
import Heading from '../../common/Heading';
import Text from '../../common/Text';
import Button from '../../common/Button';
import { PiPlayCircleFill } from 'react-icons/pi';
import { MdClose, MdOutline360 } from 'react-icons/md';
import { PropsImage } from '../main';
import { HtmlTag } from '@/components/htmlTag';
import ModalComponent from '@/components/modal/ModalComponent';
import { ReactImageTurntable } from 'react-image-turntable';
import { LandingVideoModal } from '@/components/landing/LandingVideoModal';
import { RenderHTML } from '@/components/renderHtml';
import WhereToBuyBtnEnhanced from '@/components/button/where-to-buy-btn-enhanced';
import { useDeviceDetect } from '@/utils/useDeviceDetect';
import { useStore } from 'zustand';
import { useMapStore } from '@/stores/map/mapStore';

interface landingData {
  id: string;
  description?: string;
  title?: string;
  hero_links: {
    htmlTag: string;
    id: string;
    title: string;
    url: string;
  }[];
  images: {
    data: PropsImage[] | null;
  };
  featureImages: {
    data: PropsImage[] | null;
  };
  backgroundColor: string;
  slider: any;
  panoramaImages: {
    data: PropsImage[];
  };
}

export const LandingHero: FC<{
  content: landingData;
  slug?: string;
}> = ({ content, slug }) => {
  const isMobile = useDeviceDetect();
  const [modalPanoramic, setModalPanoramic] = useState(false);
  const [turntableImg, setTurntableImg] = useState<any[]>([]);
  const turntableProps = {
    initialImageIndex: 0,
    movementSensitivity: 20,
  };
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { setModalMapOpen } = useStore(useMapStore, (state) => ({
    setModalMapOpen: state.setModalMapOpen,
  }));

  const handleModalVideo = (e: any) => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <section
        className={`LandingHero relative flex  flex-col gap-y-12 py-5 md:py-24 lg:gap-y-16 xl:py-24`}
      >
        <div className='mx-auto w-full max-w-screen-2xl gap-y-9 px-5 md:px-16 xl:px-[7.5rem]'>
          <div className='mb-5 flex flex-col gap-y-9'>
            <Heading size={2} className='text-center'>
              {content.title}
            </Heading>
            <Text size='paragraph' className='text-center lg:px-56'>
              <RenderHTML data={content.description as string} />
            </Text>
          </div>

          <div className='flex flex-row flex-wrap items-center justify-center gap-4'>
            {content.featureImages?.data?.map((item: any, index: number) => {
              return (
                <img
                  key={`${index} ${item?.id}`}
                  src={item?.attributes?.url}
                  alt={item?.attributes?.name || ''}
                  title={item?.attributes?.name || ''}
                  className='h-9 object-contain'
                />
              );
            })}
          </div>

          <div className='mt-5 flex flex-col flex-wrap items-center justify-center gap-4 gap-y-9'>
            {content?.hero_links?.map((item: any, i: number) => {
              switch (item.title) {
                case 'Where to buy':
                  return (
                    <WhereToBuyBtnEnhanced
                      key={`${i} ${item?.id}`}
                      isMobile={isMobile}
                      setMapOpen={setModalMapOpen}
                      title='Where to Buy'
                    />
                  );
                default:
              }
              return (
                <HtmlTag key={`${i} ${item?.id}`} data={item} slug={slug} />
              );
            })}
          </div>

          <div className='hero-image-container mx-auto w-full max-w-[90rem] gap-y-9 px-5'>
            <img
              src={content?.images?.data?.[0]?.attributes?.url}
              alt={content?.title}
              title={content?.title}
              className='hero-image mx-auto h-auto max-w-full cursor-pointer lg:px-[7.5rem]'
              onClick={() => {
                if (content?.panoramaImages?.data?.length) {
                  setTurntableImg(
                    content.panoramaImages.data.map(
                      (item: any) => item?.attributes?.url
                    )
                  );
                  setModalPanoramic(true);
                }
              }}
            />
          </div>

          <div className='hero-interactive-controls flex flex-row flex-wrap items-center justify-center gap-4'>
            {content?.hero_links
              ?.filter((it: any) => it.htmlTag === 'link')
              .map((item, i: number) => (
                <Fragment key={i}>
                  <Button
                    color='primary'
                    variant='text'
                    className='hero-video-trigger flex flex-row items-center justify-center gap-2.5 px-0 hover:text-[#005DCF]'
                    onClick={() => handleModalVideo(true)}
                  >
                    <PiPlayCircleFill size={28} />
                    <span>{item?.title}</span>
                  </Button>
                  {modalOpen && (
                    <LandingVideoModal
                      open={modalOpen}
                      setModalOpen={setModalOpen}
                      identifier={`product-videos-hero-${item?.id}`}
                      youtubeUrl={item?.url}
                    />
                  )}

                  <div className='interactive-controls-divider verticalLine' />
                </Fragment>
              ))}

            {content?.panoramaImages?.data &&
              content?.panoramaImages?.data?.length !== 0 && (
                <Button
                  color='primary'
                  variant='text'
                  className='hero-360-trigger flex flex-row items-center justify-center gap-2.5 px-0 hover:text-[#005DCF]'
                  onClick={() => {
                    setTurntableImg(
                      content?.panoramaImages?.data?.map(
                        (item: any) => item?.attributes?.url
                      )
                    );
                    setModalPanoramic(true);
                  }}
                >
                  <MdOutline360 size={28} />
                  <span>Explore in 360º</span>
                </Button>
              )}
          </div>
        </div>

        <ModalComponent
          size='md'
          isOpen={modalPanoramic}
          close={() => setModalPanoramic(false)}
        >
          <div className='flex w-full flex-col'>
            <div className='flex items-center justify-end px-4  pb-2 pt-4	'>
              <button
                onClick={() => setModalPanoramic(false)}
                className='inline-flex items-center justify-center rounded-lg border p-1'
              >
                <MdClose className='size-4 text-inherit' />
              </button>
            </div>
            <div className='px-10 py-2 text-center'>
              {turntableImg && (
                <ReactImageTurntable
                  images={turntableImg}
                  autoRotate={{
                    disabled: true,
                  }}
                  {...turntableProps}
                />
              )}
            </div>
          </div>
        </ModalComponent>
      </section>
    </>
  );
};
