'use client';

import { FC, useState, Fragment } from 'react';
import Heading from '../../common/Heading';
import Button from '../../common/Button';
import { PiPlayCircleFill } from 'react-icons/pi';
import { MdClose, MdOutline360 } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { PropsImage } from '../main';
import { HtmlTag } from 'components/htmlTag';
import ModalComponent from 'components/modal/ModalComponent';
import { ReactImageTurntable } from 'react-image-turntable';
import { LandingVideoModal } from 'components/landing/LandingVideoModal';
import { RenderHTML } from 'components/renderHtml';

interface landingData {
  id: string;
  description?: string;
  title?: string;
  hero_links: {
    htmlTag: string;
    id: string;
    title: string;
    url: string;
  }[];
  images: {
    data: PropsImage[] | null;
  };
  featureImages: {
    data: PropsImage[] | null;
  };
  backgroundColor: string;
  slider: any;
  panoramaImages: {
    data: PropsImage[];
  };
}

export const LandingHeroImages: FC<{
  content: landingData;
  slug?: string;
  index?: number;
}> = ({ content, slug = '', index, ...props }) => {
  const [modalPanoramic, setModalPanoramic] = useState(false);
  const [turntableImg, setTurntableImg] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const turntableProps = {
    initialImageIndex: 0,
    movementSensitivity: 20,
  };

  const handleModalVideo = (e: any) => {
      setModalOpen(!modalOpen);
    },
    [showOverlay, setShowOverlay] = useState(true);

  return (
    <>
      <section
        className={`LandingHeroImages relative flex flex-col gap-y-12 py-5 lg:gap-y-16 ${index && index !== 0 ? 'md:py-5 xl:py-5' : ''}`}
      >
        <div className='mx-auto w-full max-w-screen-2xl gap-y-9 px-5 md:px-16 xl:px-[7.5rem]'>
          <div className='flex flex-col overflow-hidden rounded-3xl bg-[#EBF5FF] md:flex-row'>
            <div className='col1 justify-end md:flex md:w-1/2 lg:order-2'>
              <div className='relative w-full'>
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className='swiper-hero'
                >
                  {content?.images?.data?.map((item: any, i: number) => (
                    <SwiperSlide key={i}>
                      <img
                        src={item?.attributes?.url}
                        alt={item?.attributes?.name}
                        title={item?.attributes?.name}
                        className='mx-auto h-auto max-w-full '
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                {content?.panoramaImages?.data &&
                  content?.panoramaImages?.data?.length !== 0 && (
                    <div
                      className='md:text-md absolute bottom-[24px] right-[24px] z-[2] flex size-9 h-11 w-11 cursor-pointer flex-row items-center justify-center rounded-full bg-white text-sm transition-all hover:bg-white hover:opacity-80'
                      onClick={() => {
                        setTurntableImg(
                          content?.panoramaImages?.data?.map(
                            (item: any) => item?.attributes?.url
                          )
                        );
                        setModalPanoramic(true);
                      }}
                    >
                      <MdOutline360 />
                    </div>
                  )}
              </div>
            </div>
            <div className='col2 p9 items-center gap-y-1 px-10 py-14 text-left md:w-1/2 lg:order-1 lg:items-start lg:py-10 lg:pl-20 xl:py-14 xl:pl-16'>
              <div className='mb-5 flex flex-col gap-y-9 text-center'>
                <Heading size={1}>{content?.title || '-'}</Heading>
              </div>
              <div className='flex flex-row flex-wrap items-center justify-center gap-4'>
                {content.featureImages?.data?.map(
                  (item: any, index: number) => {
                    return (
                      <img
                        key={index}
                        src={item?.attributes?.url}
                        alt={item?.attributes?.name || ''}
                        title={item?.attributes?.name || ''}
                        className='h-9 object-contain'
                      />
                    );
                  }
                )}
              </div>
              <div className='mt-5 flex flex-col flex-wrap items-center justify-start gap-4 gap-y-9 lg:items-start'>
                {content?.hero_links
                  ?.filter((item: any) => item?.htmlTag === 'link')
                  .map((dat: any, i: number) => (
                    <Fragment key={i}>
                      <Button
                        key={i}
                        color='primary'
                        variant='text'
                        className='flex flex-row items-center justify-center gap-2.5 px-0 hover:text-[#005DCF]'
                        onClick={() => handleModalVideo(true)}
                      >
                        <PiPlayCircleFill size={28} />
                        <span>{dat?.title}</span>
                      </Button>
                      {modalOpen && (
                        <LandingVideoModal
                          open={modalOpen}
                          setModalOpen={setModalOpen}
                          identifier={`product-videos-${dat?.id}`}
                          youtubeUrl={dat?.url}
                        />
                      )}
                    </Fragment>
                  ))}

                {content?.description && (
                  <Heading size={5} weight='font-normal'>
                    <RenderHTML
                      data={content.description}
                      className='text-center'
                    />
                  </Heading>
                )}
                {content?.hero_links?.map((item: any, i: number) => (
                  <HtmlTag key={i} data={item} slug={slug} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <ModalComponent
          size='md'
          isOpen={modalPanoramic}
          close={() => setModalPanoramic(false)}
        >
          <div className='flex w-full flex-col'>
            <div className='flex items-center justify-end px-4 pb-2 pt-4'>
              <button
                onClick={() => setModalPanoramic(false)}
                className='inline-flex items-center justify-center rounded-lg border p-1'
              >
                <MdClose className='size-4 text-inherit' />
              </button>
            </div>
            <div className='relative rounded-lg px-10 py-2 text-center'>
              {turntableImg && (
                <>
                  <ReactImageTurntable
                    images={turntableImg}
                    autoRotate={{
                      disabled: true,
                    }}
                    {...turntableProps}
                  />
                  {showOverlay && (
                    <div
                      className='pointer-events-auto absolute inset-0 flex items-center justify-center text-white'
                      onMouseDown={(e) => {
                        e.preventDefault();
                        setShowOverlay(false);
                      }}
                    >
                      <div className='flex h-24 w-24 flex-col items-center justify-center rounded-full bg-[#0071E3] text-center'>
                        <p className='text-sm font-medium'>Drag to Spin</p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </ModalComponent>
      </section>
    </>
  );
};
