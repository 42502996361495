'use client';
import { FC } from 'react';
import Text from '@/components/common/Text';
import { RebaseEcoModal } from '@/components/rebates';
import { ecoRebateData } from '@/utils/specificationHelper';
import { RenderHTML } from '@/components/renderHtml';
import { SimpleCard } from '@/components/cards/simple-card';
import './component.scss';

interface dataLandingRebates {
  content: string;
  link: string;
  buttonName?: string;
}

export const LandingRebates: FC<{
  content: dataLandingRebates;
  slug?: string;
  inx?: number;
}> = ({ content, slug = '', inx }) => {
  const rebate = ecoRebateData[slug];
  return (
    <>
      <section
        className={`LandingRebates -y-12 relative  flex flex-col py-9 lg:gap-y-16 ${inx && inx !== 0 ? 'md:py-9 xl:py-9' : ''}`}
      >
        <div className='wrapper'>
          {content.content && (
            <div
              className={`m-auto h-[120px] max-w-[976px] justify-between gap-4 rounded-2xl bg-gray-100 p-4 ${rebate && 'grid lg:grid-cols-2'}`}
            >
              <div className={`flex flex-row ${!rebate && 'justify-center'}`}>
                <Text size='paragraph' className='self-center'>
                  <RenderHTML data={content.content} />
                </Text>
              </div>
              {rebate && (
                <div className='self-center text-end'>
                  <RebaseEcoModal product={slug} id='testMrSaves' />
                </div>
              )}
            </div>
          )}

          {!content.content && (
            <div className='wrapper'>
              <SimpleCard
                title='Rebates In Your Area.'
                button={{
                  label: 'Search Now',
                  href: '/rebates',
                }}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};
