'use client';

import { FC, Fragment } from 'react';
import { LandingHero } from './landingHero';
import { LandingHeroImages } from './landingHeroImages';
import { LandingRebates } from './landingRebates';
import { LandingHeroProductShowcase } from './landingHeroProductShowcase';
import { MrSwiper } from './mrSlider/MrSwiper';
import { LandingFeatureShowcase } from './LandingFeatureShowcase';
import { LandingFeatureAccordion } from './landingFeatureAccordion';
import { LandingProductAds } from './landingProductAds';
import { LandingTabInfo } from './landingTabInfo';
import { LandingFeatureAccorNested } from './landingFeatureAccordionNest';
import { MrCards } from './mrCards';
import { MrMarquee } from './mrSlider/MrMarquee';
import { LandingTips } from './landingTips';
import { LandingFaqs } from './landingFaqs';
import { LandingDealers } from './landingDealers';
import { LandingPreviewGrid } from './landingPreviewGrid';
import { ecoRebateData } from 'utils/specificationHelper';
import { useProducts } from '@/stores/product';
import { InfoBanner } from './InfoBanner';

export interface PropsImage {
  id: string;
  attributes: {
    name: string;
    alternativeText: string | null;
    width: number;
    height: number;
    formats: any;
    url: string;
    mime: string;
    size: any;
  };
}

interface Props {
  contents: unknown[];
  createdAt: string;
  related_products: {
    data: unknown[];
  };
  product_variants?: {
    data: any[];
  };
  slug: string;
  title: string;
  updatedAt: string;
}

export const MainSection: FC<{ data: Props }> = ({ data }) => {
  const { relatedProducts } = useProducts();
  const rebate = ecoRebateData[data?.slug || ''];
  const nestedAccordion = data?.contents?.filter(
    (item: any) =>
      item?.__component === 'landing.feature-accordion' && item?.nested
  );
  const indexNested = data?.contents?.findIndex(
    (item: any) =>
      item?.__component === 'landing.feature-accordion' && item?.nested
  );

  let dataContent =
    nestedAccordion?.length !== 0
      ? data?.contents
          .map((item: any, i: number) => {
            if (i === indexNested) {
              return {
                __component: 'landing.feature-accordion-nested',
                data: nestedAccordion,
              };
            }
            return item;
          })
          .filter(
            (item: any) =>
              item?.__component !== 'landing.feature-accordion' ||
              (item?.__component === 'landing.feature-accordion' &&
                !item?.nested)
          )
      : data.contents;

  if (!rebate)
    dataContent = dataContent?.filter(
      (x: any) => x.__component !== 'landing.rebates'
    );

  return (
    <>
      {dataContent?.map((item: any, index: number) => {
        switch (item.__component) {
          case 'landing.hero':
            if (item?.slider)
              return (
                <LandingHeroImages
                  content={item}
                  slug={data.slug}
                  index={index}
                  key={index}
                />
              );
            return <LandingHero content={item} slug={data.slug} key={index} />;
          case 'landing.rebates':
            return (
              <LandingRebates content={item} slug={data.slug} key={index} />
            );
          case 'landing.hero-product-showcase':
            if (item?.data?.length !== 0)
              return <LandingPreviewGrid data={item.data} key={index} />;
            return <LandingHeroProductShowcase key={index} content={item} />;
          case 'landing.features-carousel':
            return (
              <Fragment key={index}>
                {item.with_preview ? (
                  <MrCards content={item} />
                ) : (
                  <MrSwiper size='large' data={item.data} content={item} />
                  // changing prop names to remove ambiguity with Taillwind 'sm, md, lg' classnames
                )}
              </Fragment>
            );
          case 'landing.feature-showcase':
            return <LandingFeatureShowcase content={item} key={index} />;
          case 'landing.feature-accordion':
            if (!item?.nested)
              return <LandingFeatureAccordion key={index} content={item} />;
          case 'landing.feature-accordion-nested':
            return (
              <LandingFeatureAccorNested content={item?.data} key={index} />
            );
          case 'landing.product-ads':
            return <LandingProductAds key={index} content={item} />;
          case 'landing.tabs-info':
            return <LandingTabInfo key={index} content={item} />;
          case 'landing.product-parts':
            return <MrMarquee key={index} content={item} />;
          case 'landing.tips':
            return <LandingTips key={index} content={item} />;
          case 'landing.faqs':
            return <LandingFaqs key={index} content={item} />;
          case 'landing.dealers':
            return <LandingDealers key={index} content={item} />;
          case 'landing.related-products':
            return (
              <MrSwiper
                key={`rp-${index}`}
                size='medium'
                arrow='center'
                data={data?.related_products?.data}
                content={item}
              />
            );
          case 'landing.product-variants':
            return (
              <div key={`landing.product-variants`}>
                <MrSwiper
                  key={`pv-${index}`}
                  size='small'
                  data={relatedProducts}
                  content={item}
                  slug={data.slug}
                />
              </div>
            );
          case 'landing.info-banner':
            return (
              <Fragment key={`landing.product-variants-${index}`}>
                <InfoBanner content={item} />
              </Fragment>
            );
          default:
            return <Fragment key={index}></Fragment>;
        }
      })}
    </>
  );
};
