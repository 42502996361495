'use client';
import { FC } from 'react';
import Heading from '../../common/Heading';
import Text from '../../common/Text';
import { MrImageVid } from '../../imageVidCard';
import { PropsImage } from '../main';
import { RenderHTML } from '@/components/renderHtml';

interface PropsData {
  id: string;
  description: string;
  title: string;
  tag: string;
  images: {
    data: PropsImage[];
  };
}

const PreviewData: FC<{ align: string; item: PropsData }> = ({
  align,
  item,
}) => {
  return (
    <div className='mb-9 grid w-full grid-cols-1 justify-items-center gap-y-11 md:grid-cols-2 md:gap-y-0'>
      {align === 'left' && (
        <div className='relative max-h-[576px] max-w-[582px] '>
          <MrImageVid
            varianbutton='white'
            type={
              item?.images?.data?.[0]?.attributes?.mime === 'video/mp4'
                ? 'video'
                : 'image'
            }
            url={item?.images?.data?.[0]?.attributes?.url}
            size='md'
            id='im'
          />
        </div>
      )}

      <div className='flex flex-col items-stretch justify-start gap-y-9 p-10 md:gap-y-9'>
        <Heading size={6} weight='font-normal'>
          {item?.tag}
        </Heading>
        <Heading size={3}>{item?.title}</Heading>
        <Text size='larger'>
          <RenderHTML data={item.description} />
        </Text>
      </div>
      {align === 'right' && (
        <div className='relative max-h-[576px] max-w-[582px] '>
          <MrImageVid
            varianbutton='white'
            type={
              item?.images?.data?.[0]?.attributes?.mime === 'video/mp4'
                ? 'video'
                : 'image'
            }
            url={item?.images?.data?.[0]?.attributes?.url}
            size='md'
            id='im'
          />
        </div>
      )}
    </div>
  );
};
export const LandingPreviewGrid: FC<{
  data: PropsData[];
  [key: string]: any;
}> = ({ data, ...props }) => {
  return (
    <>
      <section className='LandingPreviewGrid relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
        <div className='mx-auto mb-9 w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          {data?.map((item: PropsData, i: number) => {
            if (i % 2 === 0) {
              return <PreviewData align='left' item={item} key={i} />;
            }
            if (i % 2 !== 0) {
              return <PreviewData align='right' item={item} key={i} />;
            }
          })}
        </div>
      </section>
    </>
  );
};
