'use client';

import {
  FC,
  useMemo,
  useRef,
  useState,
  useEffect,
  SetStateAction,
} from 'react';
import { useRouter } from 'next/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { IoChevronForward } from 'react-icons/io5';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';
import { MrImageVid } from 'components/imageVidCard';
import { RenderHTML } from 'components/renderHtml';
import Heading from '../../common/Heading';
import Text from '../../common/Text';

import 'swiper/css';
import 'swiper/css/navigation';
import { Button } from 'components/button';
import FormatValue from '@/utils/FormatValue';

interface DataProps {
  title: string;
  description: string;
  data: any[];
  backgroundColor: string;
  tag: string;
}

interface Props {
  data: any[];
  header: string;
  description: string;
  size: string;
  arrow: string;
  content: Partial<DataProps>;
  slug: string;
}

export const MrSwiper: FC<Partial<Props>> = ({
  size = 'default',
  arrow = 'bottom',
  data,
  content,
  slug,
}) => {
  const router = useRouter();
  const [activeIndex, setActiveIndex] = useState(0);
  const [slides, setSlides] = useState<any>(null);
  const sliderRef: any = useRef(null);
  const sliderLength = data?.length || 0;
  const [swiper, setSwiper] = useState<any>(null);
  const nextRef: any = useRef(null);
  const prevRef: any = useRef(null);
  const [isEnd, setIsEnd] = useState(false);

  const customStyleArrow = useMemo(() => {
    let styles = '',
      arrowLeft = '',
      arrowRight = '';

    switch (arrow) {
      case 'top':
        styles = 'top-[-10%] right-0 mb-3';
        break;
      case 'center':
        styles = 'top-[50%] ';
        arrowLeft = 'absolute left-0 top-[50%]';
        arrowRight = 'absolute right-0 top-[50%]';
        break;
      default:
        styles = '';
        break;
    }
    return { styles, arrowLeft, arrowRight };
  }, [arrow]);

  const renderArrow = () => {
    if (size === 'large' && sliderLength <= 3) {
      return '';
    } else if (size !== 'large' && sliderLength <= 4) {
      return '';
    }
    return (
      <div
        className={twMerge(
          `z-[1] flex justify-end gap-2 pr-3`,
          customStyleArrow.styles
        )}
      >
        <button
          ref={prevRef}
          id='prev-arrow'
          onClick={() => swiper?.slidePrev()}
          className={twMerge(
            `custom-prev z-[1] flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white text-lg text-black shadow-md transition-all hover:bg-gray-100`,
            customStyleArrow.arrowLeft,
            activeIndex === 0 && 'opacity-40'
          )}
        >
          <MdArrowBackIos className='ml-1.5' />
        </button>

        <button
          ref={nextRef}
          id='next-arrow'
          onClick={() => swiper?.slideNext()}
          className={twMerge(
            `custom-next z-[1] flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white text-lg text-black shadow-md transition-all hover:bg-gray-100`,
            customStyleArrow.arrowRight,
            isEnd && 'opacity-40'
          )}
        >
          <MdArrowForwardIos />
        </button>
      </div>
    );
  };

  const handleSwiperChange = (swiper: { isEnd: any }) => {
    if (swiper.isEnd) {
      setIsEnd(true);
    } else {
      setIsEnd(false);
    }
  };

  const swiperParams = {
    ref: sliderRef,
    onSwiper: setSwiper,
    modules: [Navigation],
    onSlideChange: handleSwiperChange,
    onRealIndexChange: (e: { activeIndex: SetStateAction<number> }) => {
      setActiveIndex(e.activeIndex);
    },
    centeredSlidesBounds: true,
    centerInsufficientSlides: true,
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
      '640': {
        slidesPerView: 2,
      },
      '1024': {
        slidesPerView: 3,
      },
    },
  };

  useEffect(() => {
    const renderData = (item: any) => {
      return (
        <div
          className={twMerge(
            `mr-swiper-item
            relative
            flex 
            h-[500px]
            flex-col
            items-center 
            justify-center 
            overflow-hidden 
            rounded-3xl
            border-4
            sm:min-w-[280px]
            md:min-w-[280px]
            `,
            size === 'medium' &&
              'min-w-[280px] max-w-[280px] cursor-pointer bg-[#EBEFF2]'
          )}
          onClick={() =>
            size === 'medium' &&
            router.push(`/product/${item?.attributes?.slug}`)
          }
        >
          {/*Slider images or video*/}
          {size === 'small' && (
            <img
              src={FormatValue.optimizeImageUrl(item?.image_1)}
              alt='Product Image'
              className={`mb-3 max-w-[50%] rounded-3xl object-cover`}
            />
          )}
          {size === 'medium' && (
            <img
              src={FormatValue.optimizeImageUrl(
                item?.attributes?.thumbnail?.data?.attributes?.url
              )}
              alt='Product Image'
              className={`'rounded-t-3xl'} h-[273px] w-[273px] object-cover`}
            />
          )}
          {size === 'large' && (
            <MrImageVid
              varianbutton='white'
              type={
                item?.images?.data?.[0]?.attributes?.mime === 'video/mp4'
                  ? 'video'
                  : 'image'
              }
              url={FormatValue.optimizeImageUrl(
                item?.images?.data?.[0]?.attributes?.url
              )}
              id={`swiper-${item?.id}`}
              mrSwiper={true}
            />
          )}

          {size === 'medium' && (
            <div>
              <Button color='primary' className='pill-button'>
                Learn more
              </Button>
            </div>
          )}

          {/* Title and body text */}
          <div className='mr-swiper-item-content'>
            {(item?.name || item?.title) && (
              <div
                className={twMerge(
                  `mr-swiper-item-title 
                flex 
                items-center
                justify-center
                px-4`,
                  !item?.description && size !== 'small' && `h-[150px]`
                )}
              >
                {size === 'small' ? (
                  <>
                    <Heading size={4} className='text-center align-top'>
                      1{item?.name || '1-'}
                    </Heading>
                  </>
                ) : (
                  <Heading
                    size={4}
                    className={twMerge(
                      item?.description && `line-clamp-1`,
                      !item?.description && `line-clamp-4`,
                      `text-center`,
                      size === 'medium' && `pt-[75px]`
                    )}
                  >
                    {item?.title || item?.name || '-'}
                  </Heading>
                )}
              </div>
            )}

            {/* Main text */}
            {size === 'large' && item?.description && (
              <Text
                size='paragraph'
                className='
                  mr-swiper-item-description 
                  mb-6
                  line-clamp-4
                  min-h-[96px]
                  px-6
                  text-center'
              >
                <RenderHTML data={item?.description} />
              </Text>
            )}

            {/* Related Products bottom text*/}
            {size === 'small' && (
              <div className='mr-swiper-item-details'>
                <Text size='paragraph' className='mb-0.5 text-center text-xs'>
                  Multi-zone only
                </Text>
                <hr className='my-0.5 w-full' />
                <Heading size={6} className='text-center text-sm'>
                  Air Handler Model
                </Heading>
                <Text
                  size='paragraph'
                  className='mb-0 mt-0.5 text-center text-xs'
                >
                  {item?.model || '-'}
                </Text>
                <Heading size={6} className='mt-0.5 text-center text-sm'>
                  Cassette model
                </Heading>
                <Text
                  size='paragraph'
                  className='mb-0 mt-0.5 text-center text-xs'
                >
                  {item?.series || '-'}
                </Text>
              </div>
            )}
          </div>
        </div>
      );
    };

    const swiperSlides = () => {
      return (
        data &&
        data.map((item: any, idx: number) => (
          <SwiperSlide
            style={{ display: 'flex', justifyContent: 'center' }}
            className='mr-swiper-slide content-center'
            key={idx}
          >
            {renderData(item)}
          </SwiperSlide>
        ))
      );
    };

    setSlides(swiperSlides());
  }, [data, router, size]);

  return (
    <section
      className={`MrSwiper relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24 bg-[${content?.backgroundColor || '#F3F4F6'}]`}
    >
      {/* Title or Description */}
      {content && (
        <div className='mr-swiper-header mx-auto w-full max-w-screen-2xl md:px-16 xl:px-[7.5rem]'>
          {content?.title || content?.description ? (
            <div className='mr-swiper-header-content flex flex-col gap-y-9'>
              {content?.title && (
                <Heading size={2} className='text-center'>
                  {content.title}
                </Heading>
              )}
              {content?.description && (
                <Text size='paragraph' className='text-center lg:px-56'>
                  <RenderHTML data={content.description} />
                </Text>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      )}

      {/* Swiper component */}
      <div className='mr-swiper-container mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
        <div className='mr-swiper-wrapper swiper-mrcool relative w-full'>
          {arrow === 'top' && renderArrow()}
          <Swiper {...swiperParams}>{slides}</Swiper>
        </div>
        <div className={'pt-6'}>{arrow !== 'top' && renderArrow()}</div>

        {/* Related Products specifications link*/}
        {size === 'small' && (
          <div className='mr-swiper-footer flex flex-col content-center items-center justify-center text-center'>
            <button
              className='mr-swiper-footer-button place-items-center gap-x-3'
              onClick={() => router.push(`/product/${slug}/specification`)}
            >
              <Heading
                size={6}
                weight='font-semibold'
                className='flex flex-row items-center'
              >
                <Text className='p-6'>See full specifications</Text>
                <IoChevronForward size={20} />
              </Heading>
            </button>
          </div>
        )}
      </div>
    </section>
  );
};
