'use client';

import { FC, useEffect, useState, useMemo } from 'react';
import { VideoCardButton } from '../landing/LandingVideoCard';
import Image from 'next/image';
import { Container } from '../container';

export const MrImageVid: FC<{
  varianbutton?: string;
  play?: boolean;
  url: string;
  id: string;
  size?: string;
  type: string;
  title?: string;
  [key: string]: any;
  mrSwiper?: boolean | undefined;
}> = ({
  url,
  play = false,
  varianbutton,
  id,
  size = 'lg',
  type,
  title = '',
  mrSwiper = undefined,
  ...props
}) => {
  const [isPlay, setIsPlay] = useState(play);

  useEffect(() => {
    const vid = document.getElementById(id) as HTMLVideoElement;

    if (type === 'video' && isPlay && vid) {
      vid.play();
    }
  }, [isPlay, type, id]);

  const sizeCard = useMemo(() => {
    let styleCard = '',
      styleVid = '';

    switch (size) {
      case 'sm':
        styleCard = 'w-full object-cover rounded-t-3xl flex flex-col relative';
        styleVid = 'w-full relative rounded-t-3xl overflow-hidden';
        break;
      case 'md':
        styleCard =
          'bg-[#F4F6F8] rounded-[20px] w-full h-full flex flex-col items-center justify-start gap-12 relative';
        break;
      case 'lg':
        if (mrSwiper === true) {
          styleCard =
            'rounded-[20px] relative h-full w-full max-w-[475px] max-h-[360px] mb-2';
          styleVid = 'w-full h-full object-cover object-center';
        }

        break;
      default:
        styleCard =
          'w-full flex flex-col items-center justify-start gap-y-6 relative max-w-[1296px]';
        styleVid =
          'w-full relative rounded-3xl overflow-hidden aspect-[13/12] md:aspect-[35/19]';
        break;
    }
    return { styleCard, styleVid };
  }, [size]);

  return (
    <div className={`mrc-image-video ${sizeCard.styleCard}`}>
      <div className={`mrc-image-video__media-container ${sizeCard.styleVid}`}>
        {type === 'video' ? (
          <video
            src={url}
            className='mrc-image-video__video h-full w-full rounded-3xl object-cover'
            id={id}
            autoPlay
          />
        ) : url ? (
          <Image
            src={url}
            alt={title || 'image'}
            title={title || 'image'}
            width={1300}
            height={1300}
            className={`mrc-image-video__image rounded-3xl object-cover transition-all duration-300 ease-in-out ${mrSwiper && `h-full w-full`}`}
          />
        ) : (
          <Container />
        )}
      </div>
      {type === 'video' && (
        <div className='mrc-image-video__controls absolute bottom-0 left-0 right-0 z-[2] flex w-full flex-row items-center justify-end p-6'>
          <VideoCardButton
            varian={varianbutton}
            isPlaying={isPlay}
            onClick={() => setIsPlay(!isPlay)}
          />
        </div>
      )}
    </div>
  );
};
