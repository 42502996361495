'use client';
import { FC } from 'react';
import Heading from '../../common/Heading';
import Text from '../../common/Text';
import { MrImageVid } from 'components/imageVidCard';
import { MRVideo } from 'components/video/VideoCard';
import { RenderHTML } from 'components/renderHtml';
import { FeatureFlags } from 'contexts/featureFlags';

interface DataProps {
  description: string;
  title: string;
  tag?: string;
  data?: any[];
  images: {
    data: any[];
  };
}

const renderHeroVideo = (content: DataProps, className: string) => {
  if (FeatureFlags.heroVideo) {
    return (
      <section className={`hero-video-section ${className}`}>
        <div className='product-feature-video-container mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          <MRVideo
            varianbutton='blue'
            play={true}
            loop={true}
            id='feature-video'
            url={content?.images?.data?.[0]?.attributes?.url || ''}
            size='lg'
            allowUnmute={true}
            autoPlayOnView={true}
          />
        </div>
      </section>
    );
  }
  return null;
};

export const LandingHeroProductShowcase: FC<{
  content: DataProps;
}> = ({ content }) => {
  if (content.tag === 'easy-pro-hero-video') {
    return renderHeroVideo(
      content,
      'mb-6 mt-12 py-2 md:mb-16 md:mt-28 lg:mb-36 lg:mt-52'
    );
  }

  if (content.tag === 'outtasight-hero-video') {
    return renderHeroVideo(
      content,
      'mb-16 mt-24 py-2 md:mb-20 md:mt-10 lg:my-24'
    );
  }

  if (content.tag === 'olympus-e-star-hero-video') {
    return renderHeroVideo(
      content,
      'mb-16 mt-20 py-2 md:mb-16 md:mt-28 lg:mb-16 lg:mt-32'
    );
  }

  return (
    <>
      <section className='LandingHeroProductShowcase relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
        <div className='mx-auto w-full max-w-screen-2xl gap-y-9 px-5 md:px-16 xl:px-[7.5rem]'>
          <div className='flex flex-col gap-y-9'>
            <Heading size={2} className='text-center'>
              {content.title}
            </Heading>
            <Text size='paragraph' className='text-center lg:px-56'>
              <RenderHTML data={content.description} />
            </Text>
            <MrImageVid
              title={content?.title}
              varianbutton='white'
              type={
                content?.images?.data?.[0]?.attributes?.mime === 'video/mp4'
                  ? 'video'
                  : 'image'
              }
              size='lg'
              url={content?.images?.data?.[0]?.attributes?.url || ''}
              id={`lowVid`}
            />
          </div>
        </div>
      </section>
    </>
  );
};
