'use client';
import { FC, useState, ReactNode, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import Text from '../../common/Text';
import Heading from '../../common/Heading';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { IoAdd, IoClose } from 'react-icons/io5';
import { RenderHTML } from '@/components/renderHtml';

gsap.registerPlugin(ScrollTrigger);

interface ILandingFeatures {
  features?: any;
  isLoading?: boolean;
  [key: string]: any;
}

interface FeatureItem {
  id: string;
  title: string;
  description: string;
  tag: string;
  images: any;
}

const LandingFeaturesItem: FC<{
  item?: FeatureItem;
  [key: string]: any;
}> = ({ item, key, ...props }) => {
  return (
    <ImageCards
      key={key}
      title={item?.title || ''}
      description={item?.description || ''}
      image={!!item?.images?.data ? item?.images?.data[0]?.attributes?.url : ''}
      mime={!!item?.images?.data ? item?.images?.data[0]?.attributes?.mime : ''}
      withButton={false}
      {...props}
      tag={item?.tag || ''}
    />
  );
};
const CardButton: FC<{
  setIsOpenCard?: any;
  children?: ReactNode;
  varian?: string;
  [key: string]: any;
}> = ({ setIsOpenCard, children, varian = 'blue', ...props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    setIsOpenCard(isOpen);
  }, [isOpen]);
  let color = '';
  switch (varian) {
    case 'blue':
      color = 'bg-primary text-white';
      break;
    case 'white':
      color = 'bg-white text-black';
      break;
    default:
      break;
  }
  return (
    <button
      type='button'
      className={twMerge(
        'flex h-10 w-10 flex-row items-center justify-center rounded-full',
        color ? color : ''
      )}
      {...props}
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen ? <IoClose size={25} /> : <IoAdd size={25} />}
    </button>
  );
};
export const ImageCards: FC<{
  title?: string;
  description?: string;
  image?: string;
  withButton?: boolean;
  children?: ReactNode;
  tag?: string;
  mime?: string;
  [key: string]: any;
}> = ({
  title,
  description,
  image,
  withButton = true,
  children,
  tag,
  mime,
  ...props
}) => {
  const [isOpenCard, setIsOpenCard] = useState<boolean>(false);
  return (
    <div
      className={`image-cards-component mr-image-card relative flex h-[540px] w-full flex-col overflow-hidden rounded-3xl bg-[#EBF5FF] transition-all duration-300 ease-in-out`}
      {...props}
    >
      <div
        className={`flex flex-col transition-all duration-300 ease-in-out ${
          isOpenCard
            ? 'h-0 flex-shrink-0 flex-grow-0'
            : 'h-[120px] flex-shrink-0 flex-grow-0 sm:h-[125px] md:h-[130px] lg:h-[140px]'
        }`}
      >
        <div
          className={`mx-auto flex w-full flex-col items-start justify-start rounded-t-3xl bg-[#EBF5FF] px-8 py-8 text-left transition-all duration-300 ease-in-out ${
            isOpenCard ? 'opacity-0' : 'opacity-100'
          } mr-image-card-header`}
        >
          {tag && (
            <Text size='paragraph' className='mr-image-card-tag'>
              {tag}
            </Text>
          )}
          {title && (
            <Heading
              size='3'
              className='mr-image-card-title mt-2 md:text-2xl lg:text-3xl'
            >
              {title}
            </Heading>
          )}
        </div>
      </div>
      <div
        className={`transition-all duration-300 ease-in-out ${
          isOpenCard
            ? 'h-[297px] flex-shrink flex-grow'
            : 'h-[397px] flex-shrink flex-grow'
        }`}
      >
        <div
          className={`mr-image-card-image-container h-full w-full bg-slate-600 bg-transparent`}
        >
          <img
            src={image}
            alt={title}
            title={title}
            className='mr-image-card-image h-full w-full object-cover'
          />
        </div>
      </div>
      <div
        className={`mx-auto flex w-full flex-col items-start justify-start rounded-b-3xl bg-[#EBF5FF] px-8 text-left transition-all duration-300 ease-in-out ${
          isOpenCard
            ? 'h-[179px] flex-shrink flex-grow py-10 opacity-100'
            : 'h-0 flex-shrink-0 flex-grow-0 py-0 opacity-0'
        } mr-image-card-description`}
      >
        <Text size='paragraph'>
          <RenderHTML data={description as string} />
        </Text>
      </div>
      <div
        className={
          'mr-image-card-button-container absolute bottom-0 left-0 right-0 z-[2] flex w-full flex-row items-center justify-end p-6'
        }
      >
        <CardButton varian={'blue'} setIsOpenCard={setIsOpenCard} />
      </div>
    </div>
  );
};
interface PropsDatas {
  id: string;
  title: string | null;
  description: string | null;
  data: FeatureItem[];
}

export const MrCards: FC<{ content: PropsDatas }> = ({ content, ...props }) => {
  const renderGsap = () => {
    const images = document.querySelectorAll('.card-fade-on-scroll');
    images.forEach((image, i) => {
      var tl = gsap.timeline();

      tl.to(image, { opacity: 1, stagger: 1 });
      tl.to(image, { opacity: 0.3, stagger: 1 }, 1);

      // fade out
      ScrollTrigger.create({
        animation: tl,
        trigger: image,
        start: 'top center',
        end: '+=600',
        scrub: 0.1,
      });
    });
  };
  return (
    <>
      <section
        className='MrCards mr-cards-component relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'
        {...props}
      >
        <div className='mr-cards-container mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          {content.title || content.description ? (
            <div className='mr-cards-header mb-9 flex flex-col gap-y-9'>
              {content.title && (
                <Heading size={2} className='mr-cards-title text-center'>
                  {content.title}
                </Heading>
              )}
              {content.description && (
                <Text
                  size='paragraph'
                  className='mr-cards-description text-center lg:px-56'
                >
                  <RenderHTML data={content.description} />
                </Text>
              )}
            </div>
          ) : (
            ''
          )}

          <div className='mr-cards-grid m-auto grid w-full grid-cols-1 gap-y-5 md:w-[100%] md:grid-cols-2 md:gap-9 md:space-y-96 xl:w-[70%]'>
            <div className='mr-cards-column-left mx-auto flex max-w-[486px] flex-col justify-start gap-y-5 md:mx-0 md:gap-y-10'>
              {content.data &&
                content.data.map((item: FeatureItem, index: number) => {
                  if (index % 2 === 0) {
                    return <LandingFeaturesItem key={index} item={item} />;
                  }
                })}
            </div>
            <div className='mr-cards-column-right mx-auto flex max-w-[486px] flex-col justify-start gap-y-10 md:mx-0 md:gap-y-14'>
              {content.data &&
                content.data.map((item: FeatureItem, index: number) => {
                  if (index % 2 !== 0) {
                    return <LandingFeaturesItem key={index} item={item} />;
                  }
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
