'use client';
import { FC } from 'react';
import Heading from '../../common/Heading';
import Text from '../../common/Text';
import Button from '../../common/Button';
import { PropsImage } from '../main';
import { useRouter } from 'next/navigation';
import { RenderHTML } from '@/components/renderHtml';

interface PropsData {
  id: string;
  backgroundColor: string | null;
  description: string;
  title: string;
  links?: {
    id?: string;
    title?: string;
    url?: string;
    htmlTag?: string;
  };
  images: {
    data: PropsImage[];
  };
}
export const LandingTips: FC<{
  content: PropsData;
  [key: string]: any;
}> = ({ content, ...props }) => {
  const router = useRouter();

  return (
    <>
      <section className='LandingTips relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
        <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          <div className='mb-5 flex flex-col content-center items-center justify-between gap-8 rounded-2xl bg-[#EBF5FF] p-5 sm:text-center md:text-center lg:flex-row'>
            <div className='text-center lg:max-w-fit'>
              <img
                src={content?.images?.data?.[0].attributes?.url}
                alt={
                  content?.images?.data?.[0].attributes?.alternativeText ||
                  content?.images?.data?.[0].attributes?.name
                }
                title={content?.images?.data?.[0].attributes?.name}
                className='max-h-[160px] max-w-[208px] rounded-xl object-cover'
              />
            </div>
            <div className='mb-5 flex w-full flex-col self-center'>
              <Heading size={5}>{content.title}</Heading>
              <Text size='paragraph'>
                <RenderHTML data={content.description} />
              </Text>
            </div>
            <div className='w-full self-center text-center lg:max-w-fit'>
              {content?.links?.htmlTag === 'button' && (
                <Button
                  color='primary'
                  title='{content.links?.title}'
                  onClick={() => router.push(`${content?.links?.url}`)}
                >
                  {content.links?.title}
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
