import { FC, useRef, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import './accordion.scss';

interface LandingAccordionProps {
  title: string;
  description: string;
  open: boolean;
  setOpen: (key: string) => void;
}

export const LandingAccordion: FC<LandingAccordionProps> = ({
  title,
  description,
  open,
  setOpen,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState('0px');
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setHeight(open ? `${contentRef.current?.scrollHeight}px` : '0px');
    setOpacity(open ? 1 : 0);
  }, [open]);

  return (
    <div className='border-b border-gray-300'>
      <div
        className='flex cursor-pointer items-center justify-between py-4'
        onClick={() => setOpen(title)}
      >
        <h3 className='text-lg font-semibold'>{title}</h3>
        <svg
          className={`h-6 w-6 transform transition-transform duration-500 ${open ? 'rotate-180' : 'rotate-0'}`}
          fill='none'
          stroke='currentColor'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M19 9l-7 7-7-7'></path>
        </svg>
      </div>

      <div
        ref={contentRef}
        style={{
          height: height,
          opacity: opacity,
          transition: 'height 800ms ease-in-out, opacity 800ms ease-in-out',
        }}
        className='overflow-hidden'
      >
        <div className='py-4'>
          <div className='text-base leading-relaxed text-gray-800'>
            {parse(description)}
          </div>
        </div>
      </div>
    </div>
  );
};
