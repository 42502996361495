import { FC } from 'react';
import { Button } from '../../button';
import Heading from '../../common/Heading';
import { useRouter } from 'next/navigation';
import './component.scss';

interface ComponentProps {
  title: string;
  button: {
    label: string;
    href: string;
    target?: '_blank' | string;
  };
}

export const SimpleCard: FC<ComponentProps> = ({ title, button }) => {
  const router = useRouter(),
    handleClick = (href: string, target: string) => {
      if (target === '_blank') {
        window.open(href);
      } else {
        router.push(href);
      }
    };

  return (
    <div className='mrc-simple-card'>
      <div className='wrapper'>
        <Heading size={2}>{title}</Heading>
        <Button
          variant='custom'
          className='button'
          onClick={() => handleClick(button.href, button?.target || '')}
        >
          {button.label}
        </Button>
      </div>
    </div>
  );
};
