'use client';
import { FC } from 'react';
import Heading from '../../common/Heading';
import Text from '../../common/Text';
import Button from '../../common/Button';
import { useRouter } from 'next/navigation';
import { RenderHTML } from '@/components/renderHtml';

interface PropsData {
  id: string;
  description: string;
  title: string;
  backgroundColor?: string | null;
  icon: {
    data: any;
  };
  links: {
    id: string;
    title: string;
    url: string;
    hmtlTag: string;
  }[];
}

export const LandingDealers: FC<{
  content: PropsData;
  [key: string]: any;
}> = ({ content, ...props }) => {
  const router = useRouter();

  return (
    <>
      <section className='LandingDealers relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
        <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          <div
            className={`m-auto max-w-[976px] justify-between rounded-2xl p-5 text-center ${content?.backgroundColor ? `bg-[${content.backgroundColor}]` : 'bg-[#1C8EFF]'}`}
          >
            <div className='mb-5 mt-6 flex w-full flex-col items-center gap-5 self-center text-white'>
              <div>
                {content?.icon?.data?.[0]?.attributes?.url ? (
                  <img
                    width={38}
                    height={38}
                    src={content.icon.data[0].attributes.url}
                  ></img>
                ) : (
                  <svg
                    width='38'
                    height='38'
                    viewBox='0 0 38 38'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M15.6523 13.6373C15.6523 14.5253 16.0051 15.3769 16.633 16.0048C17.2609 16.6327 18.1126 16.9855 19.0006 16.9855C19.8886 16.9855 20.7402 16.6327 21.3681 16.0048C21.996 15.3769 22.3488 14.5253 22.3488 13.6373C22.3488 12.7493 21.996 11.8976 21.3681 11.2697C20.7402 10.6418 19.8886 10.2891 19.0006 10.2891C18.1126 10.2891 17.2609 10.6418 16.633 11.2697C16.0051 11.8976 15.6523 12.7493 15.6523 13.6373Z'
                      stroke='white'
                      stroke-width='2.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M12.8613 25.0257C13.8947 22.6606 16.2546 21.0078 19.0006 21.0078C21.7465 21.0078 24.1065 22.6606 25.1398 25.0257'
                      stroke='white'
                      stroke-width='2.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M2.92969 6.93973C2.92969 8.00533 3.353 9.02729 4.10649 9.78079C4.85998 10.5343 5.88194 10.9576 6.94755 10.9576C8.01315 10.9576 9.0351 10.5343 9.7886 9.78079C10.5421 9.02729 10.9654 8.00533 10.9654 6.93973C10.9654 5.87413 10.5421 4.85217 9.7886 4.09868C9.0351 3.34518 8.01315 2.92188 6.94755 2.92188C5.88194 2.92188 4.85998 3.34518 4.10649 4.09868C3.353 4.85217 2.92969 5.87413 2.92969 6.93973Z'
                      stroke='white'
                      stroke-width='2.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M10.2951 27.7031H3.59863V34.3996H10.2951V27.7031Z'
                      stroke='white'
                      stroke-width='2.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M34.4025 3.59375H27.7061V10.2902H34.4025V3.59375Z'
                      stroke='white'
                      stroke-width='2.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M2.05573 23.0123C1.75106 21.7225 1.58984 20.3773 1.58984 18.9944C1.58984 17.6116 1.75106 16.2663 2.05573 14.9766'
                      stroke='white'
                      stroke-width='1.25'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M14.9824 2.05183C16.2722 1.74716 17.6174 1.58594 19.0003 1.58594C20.3831 1.58594 21.7284 1.74716 23.0181 2.05183'
                      stroke='white'
                      stroke-width='1.25'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M35.9453 14.9766C36.2499 16.2663 36.4111 17.6116 36.4111 18.9944C36.4111 20.3773 36.2499 21.7225 35.9453 23.0123'
                      stroke='white'
                      stroke-width='1.25'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M14.9824 35.9375C16.2722 36.2421 17.6174 36.4033 19.0003 36.4033C20.3831 36.4033 21.7284 36.2421 23.0181 35.9375'
                      stroke='white'
                      stroke-width='1.25'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M27.0361 31.0491C27.0361 31.5767 27.1401 32.0992 27.342 32.5867C27.5439 33.0741 27.8398 33.5171 28.2129 33.8902C28.586 34.2633 29.029 34.5592 29.5164 34.7611C30.0039 34.963 30.5264 35.067 31.054 35.067C31.5816 35.067 32.1041 34.963 32.5916 34.7611C33.079 34.5592 33.522 34.2633 33.895 33.8902C34.2681 33.5171 34.5641 33.0741 34.766 32.5867C34.9679 32.0992 35.0718 31.5767 35.0718 31.0491C35.0718 30.5215 34.9679 29.999 34.766 29.5115C34.5641 29.0241 34.2681 28.5811 33.895 28.2081C33.522 27.835 33.079 27.539 32.5916 27.3371C32.1041 27.1352 31.5816 27.0312 31.054 27.0312C30.5264 27.0312 30.0039 27.1352 29.5164 27.3371C29.029 27.539 28.586 27.835 28.2129 28.2081C27.8398 28.5811 27.5439 29.0241 27.342 29.5115C27.1401 29.999 27.0361 30.5215 27.0361 31.0491Z'
                      stroke='white'
                      stroke-width='2.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                )}
              </div>
              <Heading size={3}>{content.title}</Heading>
              <Text size='paragraph'>
                <RenderHTML data={content.description} />
              </Text>
              {content?.links?.map((item: any, i: number) => (
                <Button
                  key={i}
                  color='primary'
                  title={item.title}
                  onClick={() => router.push(item.url)}
                  className='w-fit bg-white font-semibold  text-[#1C8EFF] hover:bg-slate-200 hover:text-[#005DCF] focus:bg-slate-200'
                >
                  {item.title}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
