import { FC, useEffect, useState } from 'react';
import getYouTubeID from 'get-youtube-id';

const LandingVideoModal: FC<{
  open?: boolean;
  setModalOpen: any;
  identifier: string;
  youtubeUrl?: string;
  youtubeId?: string;
  [key: string]: any;
}> = ({
  identifier,
  youtubeUrl,
  youtubeId,
  open = false,
  setModalOpen,
  ...props
}) => {
  const embedCode =
      youtubeId || (youtubeUrl ? getYouTubeID(youtubeUrl) : undefined),
    [isOpen, setIsOpen] = useState<boolean>(open),
    youtubeEmbed = `https://www.youtube-nocookie.com/embed/${embedCode}?controls=0`;

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const handleCloseModal = () => {
    setIsOpen(false);

    let containerElement: any = document.getElementById(
        `hs-modal-${identifier}`
      ),
      iframe_tag = containerElement.querySelector('iframe'),
      video_tag = containerElement.querySelector('video');

    if (iframe_tag) {
      let src = iframe_tag.src;
      iframe_tag.src = src;
    }

    if (video_tag) {
      video_tag.pause();
    }
  };

  return (
    <>
      <div
        id={`hs-modal-${identifier}`}
        className={
          isOpen
            ? `open pointer-events-none fixed start-0 top-0 z-[80] size-full overflow-y-auto overflow-x-hidden bg-black opacity-100 transition-all duration-500`
            : ''
        }
        {...props}
      >
        <div className='m-0 h-full w-full opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500'>
          <div className='pointer-events-auto relative flex h-full flex-col items-stretch justify-center border-0 bg-black shadow-none'>
            <button
              type='button'
              className='absolute right-10 top-8 z-[2] ml-auto flex size-7 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-white transition-all hover:text-white/50 disabled:pointer-events-none disabled:opacity-50'
              data-hs-overlay={`#hs-modal-${identifier}`}
            >
              <span className='sr-only' onClick={handleCloseModal}>
                Close
              </span>
              <svg
                onClick={handleCloseModal}
                className='size-4 flex-shrink-0'
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M18 6 6 18' />
                <path d='m6 6 12 12' />
              </svg>
            </button>
            <div className='flex h-full w-full flex-grow flex-col items-center justify-center overflow-y-auto bg-black px-5'>
              <div className='aspect-video h-5/6 max-w-full text-gray-800'>
                <iframe
                  width='100%'
                  height='100%'
                  src={youtubeEmbed}
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  referrerPolicy='strict-origin-when-cross-origin'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { LandingVideoModal };
