'use client';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import Marquee from 'react-fast-marquee';
import Text from 'components/common/Text';
import Heading from 'components/common/Heading';
import { PropsImage } from 'components/section/main';
import { RenderHTML } from 'components/renderHtml';

interface PropsData {
  id: string;
  description: string;
  title: string;
  data: {
    id: string;
    title: string;
    image?: {
      data: PropsImage;
    };
  }[];
}

export const MrMarquee: FC<{ content: PropsData }> = ({ content }) => {
  const renderData = (item: any, i: number) => {
    return (
      <div key={i} className={twMerge(`relative mr-5 w-[376px] rounded-3xl`)}>
        <div className={`flex flex-col py-6 text-center`}>
          <Text>{item?.title}</Text>
        </div>
        <div className='h-[328px] rounded-3xl  bg-[#F9FAFB]'>
          {item?.image?.data?.attributes?.url && (
            <img
              src={item?.image?.data?.attributes?.url}
              alt={item?.title}
              title={item?.title}
              className='h-full w-full rounded-3xl object-cover'
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <section className='MrMarquee relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
        <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          <div className='grid gap-x-24 gap-y-9 md:px-40 lg:grid-cols-2 lg:px-40 xl:px-56'>
            <Heading size={2}>{content.title}</Heading>
            <Text size='paragraph'>
              <RenderHTML data={content.description} />
            </Text>
          </div>
        </div>
        <div className='relative w-full'>
          <Marquee>
            {content.data &&
              content.data.map((item: any, i: number) => renderData(item, i))}
          </Marquee>
        </div>
      </section>
    </>
  );
};
