import { create } from 'zustand';
import AxiosInstance, { AxiosRequestConfig } from 'axios';
import { Meta, LandingPage } from './types';

interface LandingPageState {
  landingPage: LandingPage | any;
  isLoading: boolean;
  isError: boolean;
  getLandingPage: (params: any) => Promise<void>;
  isResetLandingPage: () => void;
  meta: Meta;
}

const axios = AxiosInstance.create({
  baseURL: process.env.API_ENDPOINT,
});

export const prefix_detail = '/landing-page';

export const useLandingPage = create<LandingPageState>((set) => ({
  landingPage: {},
  isLoading: false,
  isError: false,
  meta: {
    pagination: {
      page: 1,
      pageSize: 10,
      pageCount: 1,
      total: 0,
    },
  },
  getLandingPage: async ({
    id,
    params,
  }: {
    id: any;
    params?: AxiosRequestConfig;
  }) => {
    set({ isLoading: true });
    try {
      const response = await axios.get(`${prefix_detail}/${id}`, { params });
      set({ landingPage: response.data?.data });
      set({ isLoading: false });
      set({ isError: false });
    } catch (error) {
      console.error('Error fetching landing page:', error);
      set({ isLoading: false });
      set({ isError: true });
    }
  },
  isResetLandingPage: () => set({ landingPage: undefined }),
}));
