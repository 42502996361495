import { FC } from 'react';
import Button from '../common/Button';
import { useRouter } from 'next/navigation';
import { RebaseEcoModal } from '../rebates';
interface Props {
  htmlTag: string;
  id: string;
  title: string;
  url: string;
}
export const HtmlTag: FC<{
  data: Props;
  slug?: string;
  [key: string]: any;
}> = ({ data, slug = '', ...props }) => {
  const router = useRouter(),
    url = (data.url ?? `/product/detail/${slug}`) || undefined;

  if (data.htmlTag === 'button' && url) {
    return (
      <Button
        color='primary'
        title={data.title}
        onClick={() => router.push(url)}
      >
        {data.title}
      </Button>
    );
  }

  if (data.htmlTag === 'rebates') {
    return <RebaseEcoModal product={slug} id={`herolanding01${data.id}`} />;
  }
};
