'use client';
import { FC } from 'react';
import Text from '@/components/common/Text';
import Heading from '@/components/common/Heading';
import { PropsImage } from '../main';
import { RenderHTML } from '@/components/renderHtml';

interface PropsData {
  id: string;
  backgroundColor?: string;
  description: string;
  title: string;
  image: {
    data: PropsImage;
  };
}
export const LandingProductAds: FC<{
  content: PropsData;
  [key: string]: any;
}> = ({ content, ...props }) => {
  return (
    <>
      <section className='LandingProductAds relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
        <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          <div
            className={`m-auto max-w-[976px] justify-center rounded-2xl bg-[#F3F4F6]`}
          >
            <div className='flex flex-col items-center text-center'>
              <div className='flex flex-col gap-y-9 py-14 pb-8 lg:px-16'>
                <Heading size={2}>{content.title}</Heading>
                <Text size='paragraph' className='px-5 lg:px-24'>
                  <RenderHTML data={content.description} />
                </Text>
              </div>
              <div className='max-w-[720px] overflow-hidden'>
                <img
                  src={content?.image?.data?.attributes?.formats?.medium?.url}
                  alt={''}
                  title={''}
                  className='h-full w-full rounded-3xl object-cover '
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
