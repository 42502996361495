'use client';
import { FC, useState } from 'react';
import Heading from '../../common/Heading';
import { LandingAccordion } from '../../landing/LandingAccordion';
import { MrImageVid } from '../../imageVidCard';
import { IoChevronBackOutline } from 'react-icons/io5';
import { PropsImage } from '../main';

interface Props {
  description: string;
  title: string;
  data: PropsData[];
  images: { data: PropsImage[] };
  tag: string | null;
}

interface PropsData {
  description: string;
  tag?: string;
  title: string;
}

const TRANSITION_DURATION = 500;

const CardAccordionPreview: FC<{
  data: Props;
  positionAccor: 'left' | 'right';
}> = ({ positionAccor, data }) => {
  const [accordionOpen, setAccorOpen] = useState<string | null>(null);
  const [indexOpen, setIdxOpen] = useState<number>(0);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [imgLoaded, setImgLoaded] = useState<boolean>(true);
  const [currentUrl, setCurrentUrl] = useState<string>(
    data?.images?.data?.[0]?.attributes?.url || ''
  );

  const handleToggle = (key: string) => {
    if (isTransitioning) return;

    const findIx = data?.data.findIndex((item) => item?.title === key);
    const newIndex = findIx + 1;

    if (newIndex !== indexOpen) {
      setIsTransitioning(true);
      setImgLoaded(false);

      setTimeout(() => {
        setIdxOpen(newIndex);
        setAccorOpen(accordionOpen !== key ? key : null);
        setCurrentUrl(data?.images?.data?.[newIndex]?.attributes?.url || '');
        setImgLoaded(true);
        setIsTransitioning(false);
      }, TRANSITION_DURATION);
    } else {
      setAccorOpen(accordionOpen !== key ? key : null);
    }
  };

  const handleBack = () => {
    if (isTransitioning) return;

    setIsTransitioning(true);
    setImgLoaded(false);

    setTimeout(() => {
      setIdxOpen(0);
      setAccorOpen(null);
      setCurrentUrl(data?.images?.data?.[0]?.attributes?.url || '');
      setImgLoaded(true);
      setIsTransitioning(false);
    }, TRANSITION_DURATION);
  };

  const imageDefault = data?.images?.data?.[0]?.attributes;
  const isVideo = imageDefault?.mime === 'video/mp4';

  const ImageContainer = (
    <div className='relative max-h-[500px] max-w-[500px]'>
      <div
        className={`transition-all duration-500 ease-[cubic-bezier(0.4,0,0.2,1)] ${
          imgLoaded ? 'opacity-100' : 'opacity-0'
        }`}
        style={{
          minHeight: '500px',
          maxHeight: '500px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MrImageVid
          varianbutton='white'
          type={isVideo ? 'video' : 'image'}
          url={currentUrl}
          size='md'
          id={`im-${positionAccor}`}
        />
      </div>
    </div>
  );

  return (
    <div className='mrc-accorian-preview mb-9 grid w-full grid-cols-1 items-center justify-items-center gap-y-9 md:max-h-[576px] md:grid-cols-2 md:gap-x-9 md:gap-y-0'>
      {positionAccor === 'right' && ImageContainer}

      <div className='flex flex-col items-stretch justify-start gap-y-9 p-10 md:gap-y-9'>
        <button
          className='flex flex-row place-items-center gap-x-3 text-left'
          onClick={handleBack}
          aria-label='Back to overview'
          type='button'
        >
          {accordionOpen && <IoChevronBackOutline />}
          {data?.tag && (
            <Heading size={6} weight='font-normal'>
              {data.tag}
            </Heading>
          )}
        </button>

        <div className='accordion-section-title'>
          <Heading size={3}>{data?.title}</Heading>
        </div>

        {data?.data?.map((item, i) => (
          <LandingAccordion
            key={i}
            title={item.title}
            description={item.description}
            open={accordionOpen === item.title}
            setOpen={handleToggle}
          />
        ))}
      </div>
      {positionAccor !== 'right' && ImageContainer}
    </div>
  );
};

export const LandingFeatureAccorNested: FC<{
  content?: Props[];
}> = ({ content = [] }) => (
  <section className='LandingFeatureAccorNested relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
    <div className='mx-auto w-full max-w-screen-2xl gap-y-9 px-5 md:px-16 xl:px-[7.5rem]'>
      {content.map((item, i) => (
        <CardAccordionPreview
          key={i}
          positionAccor={i % 2 === 0 ? 'left' : 'right'}
          data={item}
        />
      ))}
    </div>
  </section>
);
