'use client';
import { FC, useState, useEffect, useMemo } from 'react';
import Heading from '../../common/Heading';
import Text from '../../common/Text';
import { LandingAccordion } from './LandingAccordion';
import { RenderHTML } from '@/components/renderHtml';
import { MrImageVid } from '../../imageVidCard';
import './accordion.scss';

interface DataProps {
  description: string;
  title: string;
  data: any[];
  images: { data: any[] };
}

export const LandingFeatureAccordion: FC<{
  content: DataProps;
  [key: string]: any;
}> = ({ content }) => {
  const [accordionOpen, setAccordionOpen] = useState<string>(
    content?.data?.[0]?.title || ''
  );
  const [idxImg, setIdxImg] = useState<number>(0);
  const [imgLoaded, setImgLoaded] = useState<boolean>(true);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>(
    content?.images?.data?.[0]?.attributes?.url || ''
  );
  const [imgType, setImgType] = useState<string>(
    content?.images?.data?.[0]?.attributes?.mime || ''
  );

  const imageData = useMemo(() => content?.images?.data, [content]);

  useEffect(() => {
    if (!isTransitioning) {
      const newMedia = imageData?.[idxImg]?.attributes || {};
      setImgUrl(newMedia.url || '');
      setImgType(newMedia.mime || '');
    }
  }, [idxImg, isTransitioning, imageData]);

  const handleToggle = (key: string) => {
    const newImgIdx =
      content?.data?.findIndex((x: any) => x.title === key) || 0;

    if (newImgIdx !== idxImg) {
      setIsTransitioning(true);
      setImgLoaded(false);

      setTimeout(() => {
        setIdxImg(newImgIdx);
        setImgLoaded(true);
        setIsTransitioning(false);
      }, 400);
    }

    setAccordionOpen(accordionOpen !== key ? key : '');
  };

  return (
    <section className='LandingFeatureAccordion relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
      <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
        <div className='mb-9 flex flex-col gap-y-9'>
          <Heading size={2} className='text-center'>
            {content.title}
          </Heading>
          <Text size='paragraph' className='text-center lg:px-56'>
            <RenderHTML data={content.description} />
          </Text>
        </div>

        <div className='feature-content-grid grid w-full grid-cols-1 items-center gap-y-9 md:grid-cols-2 md:gap-x-[60px] md:gap-y-0'>
          <div
            className={`feature-media-showcase relative mx-auto w-full max-w-[580px] transition-opacity duration-700 ease-in-out md:mx-0 ${
              imgLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            style={{
              minHeight: '576px',
              maxHeight: '576px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MrImageVid
              varianbutton='white'
              type={imgType === 'video/mp4' ? 'video' : 'image'}
              url={imgUrl}
              size='md'
              id='current-media'
              className='mrc-image-video'
            />
          </div>

          <div className='accordion-content-wrapper flex flex-col items-stretch justify-start gap-y-7 md:gap-y-7'>
            {content.data.map((item: any, i: number) => (
              <LandingAccordion
                key={i}
                title={item.title}
                description={item.description}
                open={accordionOpen === item.title}
                setOpen={handleToggle}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
